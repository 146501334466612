import { useRecoilValue } from "recoil";
import { reportlistAtom, reportAtom } from "../_state";
import { AnalyticsGrid } from "./AnalyticsGrid"
import { AnalyticsList } from "./AnalyticsList"

function AnalyticsView( {results_type, data, viewcolumns, viewrows} ) {
    const reportlist= useRecoilValue(reportlistAtom)
    const report = useRecoilValue(reportAtom);


    if (results_type === 'grid'){
      return(<AnalyticsGrid viewsheet = {report} viewcolumns = {viewcolumns} viewrows = {viewrows}/>)
    } else if (results_type === 'list'){
        return (<AnalyticsList reports = {reportlist} viewcolumns = {viewcolumns} viewrows = {viewrows} />)

    }
  }
  export { AnalyticsView }
  