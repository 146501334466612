import { useState, useEffect } from 'react';
import {
  useParams,
} from "react-router-dom";
import { 
  Button, 
  Form,
  Input,
  Select,
  Space,
  Spin,
  Switch} from 'antd';
import { centerAtom, allCountriesAtom, dataloadingAtom} from '../../_state';
import { useRecoilValue, useRecoilState } from 'recoil';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { DataActions } from '../../_actions';

function Center() {
  const dataActions = DataActions();
  const countries = useRecoilValue(allCountriesAtom)
  const { id } = useParams();
  const [viewMode, setViewMode] = useState(true)
  const [loading, setLoading] = useState(true)
  const [pending, setPending] = useState(false)
  // const { Option } = Select;
  const [form] = Form.useForm()
  const center = useRecoilValue(centerAtom)
  const [dataloading, setDataloading] = useRecoilState( dataloadingAtom )


  useEffect(() => {
    if(center['id'] !== id) {
      setDataloading(true)
      dataActions.getCenterDetails(id)}
    else if (center['id'] === id) {
      setDataloading(false)
    }
  }, [center])
  

  // dynamic forms to add representative
  // state
  const [inputFields, setInputFields] = useState([
    {name: '', email: ''}
  ])
  // add fields button handler
  const addFields = () => {
    let newfield = { name: '', email: '' }
    setInputFields([...inputFields, newfield])
  }
  // remove fields button handler
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1)
    setInputFields(data)
}
  // form change handler
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  }

   function handleFinish(values) {
      const data = values
      const params = {id: center['id']}

    setPending(true)
    setTimeout(() => {
      setPending(false)
        dataActions.updateCenter(data, params)//TODO: post values / update the values (values)
      setViewMode(true)
    }, 1500)
  }

  return (
    dataloading ? <Spin/>:
    <Form name="center" initialValues={center} form={form} layout='horizontal' onFinish={handleFinish} autoComplete="off">
                  <h1 style={{ height: '40px', fontSize: '16px', marginTop: '10px', color: '#888' }}>
                  Center Information
                  {viewMode && (
                    <Button type="link" onClick={() => setViewMode(false)} style={{ float: 'right' }}>
                      Edit
                    </Button>
                  )}
                  </h1>
                  <Form.Item name='name' label="Name">
                    <Input disabled={viewMode}/>
                  </Form.Item>
                  <Form.Item name='name_2' label="Name Extension">
                    <Input disabled={viewMode}/>
                  </Form.Item>
                  <Form.Item name='region' label="Region">
                    <Input disabled={viewMode}/>
                  </Form.Item>
                  <Form.Item name='wbmt_id' label="WBMT ID">
                    <Input readOnly={true}/>
                  </Form.Item>
                  <Form.Item name='address' label="Address">
                    <Input disabled={viewMode}/>
                  </Form.Item>
                  <Form.Item name='address_2' label="Address Extentions">
                    <Input disabled={viewMode}/>
                  </Form.Item>
                  <Form.Item name='postal_code' label="Postal Code">
                    <Input disabled={viewMode}/>
                  </Form.Item>
                  <Form.Item name='city' label="City">
                    <Input disabled={viewMode}/>
                  </Form.Item>
                  <Form.Item name='country_iso' label="Country">
                    <Select disabled={viewMode} options={countries} fieldNames={{label:'name', value:'iso', groupLabel: 'iso'}} showSearch>
                    {/* {countries.map(country => (
                      <Option key={country.iso} value={country.name + ' ' + '(' + country.iso + ')'}>
                      </Option>
                    ))} */}
                    </Select>
                  </Form.Item>
                  <Form.Item name='active' label="Active">
                    <Switch disabled={viewMode}/>
                  </Form.Item>
                <h1 style={{ height: '40px', fontSize: '16px', marginTop: '10px', color: '#888' }}>
                  Representatives
                  </h1>
    <Form.List name="representatives" initialValue={center['representatives']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{
                display: 'flex',
                // marginBottom: 8,
              }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'name']}
                rules={[
                  {
                    required: true,
                    message: 'Missing name',
                  },
                ]}
              >
                <Input disabled={viewMode} placeholder="Name"/>
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'email']}
                rules={[
                  {
                    required: true,
                    message: 'Missing Email',
                  },
                ]}
              >
                <Input disabled={viewMode} placeholder="Email"/>
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" disabled={viewMode} onClick={() => add()} block icon={<PlusOutlined />}>
              Add field
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
    {!viewMode && (
                    <>
                    <Form.Item className="form-footer" wrapperCol={{ span: 16, offset: 4 }}>
                      <Button htmlType="submit" type="primary" disabled={false} //{pending}
                      >
                        {pending ? 'Updating...' : 'Update'}
                      </Button>
                      <Button
                        onClick={() => {
                          form.resetFields()
                          setViewMode(true)
                        }}
                        style={{ marginLeft: '15px' }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    </>
                  )}

  </Form>
  )
}

export { Center }
