import { 
  Divider,
  InputNumber,
  Row, 
  Col,
  Space,
  Table,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Report } from '../types';

    /**
   * Function for returning the value of the object to show in form component
   * @param {*} sheet 
   * @param {*} ind 
   * @param {*} don 
   * @returns value
   */
function showValue(sheet: { [x: string]: { [x: string]: any; }; }, ind: { indication_id: string | number; }, don: { donor_type_id: string | number; }) {
    try {
        return sheet[ind.indication_id][don.donor_type_id] === 0? null : sheet[ind.indication_id][don.donor_type_id]
    } catch (error) {
        //console.log("there is no value to show:", error)
        return null
        //setSheet(sheet => ({...sheet, [ind.indication_id] : {...sheet[ind.indication_id], [don.donor_type_id]: 0}}))
    }
    };

function AnalyticsList( {reports, viewcolumns, viewrows} ) {

  const columns: ColumnsType<Report> = [
    {
      title: 'Center',
      width: 50,
      dataIndex: 'center',
      key: 'center',
      fixed: 'left',
    },
    {
      title: 'Year',
      width: 15,
      dataIndex: 'reporting_year',
      key: 'reporting_year',
      fixed: 'left',
    },
    {
      title: 'Num of Teams',
      width: 15,
      dataIndex: 'num_teams_reported',
      key: 'num_teams_reported',
      fixed: 'left',
    },
    {
      title: 'Content',
      dataIndex: 'report_content.TOT.HSCT_Total',
      key: 'report_content.TOT.HSCT_Total',
      width: 150,
    },
  ];
  
    return(<>
      <Table columns={columns} dataSource={reports} scroll={{ x: 1500, y: 300 }} />
  </>)
}
export { AnalyticsList }
