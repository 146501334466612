import { 
  Divider,
  InputNumber,
  Row, 
  Col,
  Space,
  Tooltip,
} from 'antd';

    /**
   * Function for returning the value of the object to show in form component
   * @param {*} sheet 
   * @param {*} ind 
   * @param {*} don 
   * @returns value
   */
function showValue(sheet: { [x: string]: { [x: string]: any; }; }, ind: { indication_id: string | number; }, don: { donor_type_id: string | number; }) {
    try {
        return sheet[ind.indication_id][don.donor_type_id] === 0? null : sheet[ind.indication_id][don.donor_type_id]
    } catch (error) {
        //console.log("there is no value to show:", error)
        return null
        //setSheet(sheet => ({...sheet, [ind.indication_id] : {...sheet[ind.indication_id], [don.donor_type_id]: 0}}))
    }
    };

function AnalyticsGrid( {viewsheet, viewcolumns, viewrows} ) {
    return(<>
<Row wrap={false}>
  <Col span={3}>
  </Col>
  {(viewcolumns).map((data) => {
    return <Col span={1}>
          <Space align="end">
          <Tooltip title={<div>{data.donor_type_group}<br/>{data.donor_type_long}<br/></div>}><center>{data['donor_type_txt']}</center></Tooltip>
          </Space>
          </Col>
          })}
</Row>
  {(viewrows).map(ind => {
      return <Row wrap={false}>
        <Col span={3}>
          <Tooltip title={<div>{ind.indication_long}<br/>{ind.indication_txt}<br/>{ind.category_long}<br/>{ind.subcategory_long}</div>}>{ind['indication_txt']}</Tooltip>
        </Col>
            {(viewcolumns).map((don) => {
                return <Col span={1}>
                  <InputNumber 
                    style={{
                    width: "100%", 
                    border: "1px solid #cccccc", 
                    background: (ind.type === "total" || don.type === "total") ? '#DFDFDF' : 'white'}}
                    size='small'
                    value={showValue(viewsheet.report_content, ind, don)} 
                    controls={false}
                    readOnly={true}
                    data-indication={ind.indication_id}
                    data-donortype={don.donor_type_id}
                    step={1}
                    min={0}
                    bordered={false}
                    />
                  </Col>
            })}
          </Row>
      })}
  <Divider />
  </>)
}
export { AnalyticsGrid }