import {useState} from 'react';
import {
  Table, 
  Tooltip 
} from 'antd';

import { useRecoilValue, useRecoilState } from 'recoil';
import { countriesAtom, centersAtom, dataloadingAtom } from '../../_state';
import { DataActions } from '../../_actions';
// import { Link } from 'react-router-dom'
import { Center } from '../../types';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

function CenterList() {
//   // handling states
//   const [searchText, setSearchText] = useState('')
//   const [searchedColumn, setSearchedColumn] = useState('')
  const [editable, setEditable] = useState(false)
//   const [loading, setLoading] = useState(false)
//   const [modalVisible, setModalVisible] = useState(false)
//   const [centerForm, setCenterForm] = useState({})

  const dataActions = DataActions();
//   const setCenterSelected = useSetRecoilState(centerSelectedAtom)
//   const reportlist = useRecoilValue(reportlistAtom)
  const centers = useRecoilValue( centersAtom )
  const countries = useRecoilValue ( countriesAtom )
  const [dataloading, setDataloading] = useRecoilState( dataloadingAtom )




//   // Modal to edit centers and form builder
//   const [form] = Form.useForm()
//   const [viewMode, setViewMode] = useState(true)
//   const [pending, setPending] = useState(false)
//   const [selectedCenter, setSelectedCenter] = useState(null)

//   // const handleFinish = useCallback(values => {
//   //     const data = values
//   //     const params = {id: selectedCenter?.id}
//   //   console.log('Submit: ', values)
//   //   console.log("Check center data:", data)
//   //   console.log("Check center params:", params)

//   //   setPending(true)
//   //   setTimeout(() => {
//   //     setPending(false)
//   //       dataActions.updateCenter(data, params)//TODO: post values / update the values (values)
//   //     setViewMode(true)
//   //     setModalVisible(false);
//   //   }, 1500)
//   // })




//   const makeEditable = () => {
//     setEditable(!editable);
//   }

//   const getMetaCenter = () => {
//     const meta_center = {
//       columns: 2,
//       disabled: pending,
//       dynamic: true,
//       initialValues: selectedCenter,
//       fields: [
//         { key: 'name', label: 'Name', required: true },
//         { key: 'name_2', label: 'Additional Description', required: false},
//         { key: 'region', label: 'Region', required: false, readOnly: true },
//         { key: 'wbmt_id', label: 'WBMT ID', required: false , readOnly: true},
//         { key: 'wbmt_gctn', label: 'WBMT GCTN', required: false , readOnly: true},
//         { key: 'address', label: 'Address', colSpan: 2 },
//         { key: 'address_2', label: 'Address Extended', colSpan: 2 },
//         { key: 'postal_code', label: 'Zip Code' },
//         { key: 'city', label: 'City', required: true},
//         { key: 'country_name', label: 'Country', readOnly: true },
//         { key: 'type', label: 'Type of Organization', widget: 'select', options: [{'value': 'TC', 'label': 'Transplant Center'}, {'value':'CO', 'label':'Country Organization'}], readOnly: true},
//         { key: 'active', label: 'Active', widget: 'switch' },
//       ],
//     }
//     return meta_center
//   }

//   // const getMetaRepresentative = () => {
//   //   const meta_representatives = {
//   //     columns: 2,
//   //     disabled: pending,
//   //     dynamic: true,
//   //     initialValues: selectedCenter,
//   //     fields: [
//   //       { key: 'representatives.first_name', label: 'Representative First Name', required: false},
//   //       { key: 'representatives.last_name', label: 'Representative Last Name', required: false},
//   //       { key: 'representatives.email', label: "Representative Email", rules: [{ type: 'email', message: 'Invalid email'}], required: true},

//   //     ],
//   //   }
//   //   return meta_representatives
//   // }


//   function handleReset(clearFilters) {
//     clearFilters();
//     setSearchText('');
//   }; 


//   const items: MenuProps['items'] = [
//     {
//       label: 'Change Status',
//       key: '1',
//     },
//     {
//       label: 'Delete',
//       key: '2',
//     },
//   ];

//   const expandedRowRender = (record) => {
//     console.log("Record:", record)
//     const params = {center: record.id};
//     console.log(reportlist)
//     const columns = [
//       { title: 'Year', dataIndex: 'reporting_year', key: 'reporting_year', sorter: (a, b) => sorter(a.reporting_year, b.reporting_year)},
//       { title: 'Reporter', dataIndex: 'reporter', key: 'reporter' },
//       { title: 'Report Status',
//         key: 'status',
//         render: () => (
//           <span>
//             <Badge status="success" />
//             Finished
//           </span>
//         ),
//       },
//       { title: 'Number of Teams', dataIndex: 'num_teams_reported', key: 'num_teams_reported' },
//       { title: 'Number of Patients', dataIndex: 'totalPatientsReported', key: 'totalPatientsReported' },
//       { title: 'Action',
//         dataIndex: 'operation',
//         key: 'operation',
//         render: () => (
//           <Space size="middle">
//             <a>View</a>
//             <a>Edit</a>
//             <Dropdown menu={{items}}>
//               <a>
//                 More <DownOutlined />
//               </a>
//             </Dropdown>
//           </Space>
//         ),
//       },
//     ];
//   return <Table columns={columns} dataSource={reportlist} pagination={false} />;
//   };
  

//   const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
    const columns: ColumnsType<Center> = [
            {
              title: 'CIC',
              key: 'region',
              dataIndex: 'region',
              width: '2%',
              fixed: 'left',
              filters: [
                {
                  text: "101: SOUTH-EAST ASIA / WESTERN PACIFIC",
                  value: 101,
                },
                {
                  text: "102: CANADA",
                  value: 102,
                },
                {
                  text: "103: NORTH AMERICA",
                  value: 103,
                },
                {
                  text: "104: EUROPE",
                  value: 104,
                },
                {
                  text: "105: EASTERN MEDITERRANEAN / AFRICA",
                  value: 105,
                },
                {
                  text: "106: SOUTH & MIDDLE AMERICA",
                  value: 106,
                }
              ],
              // onFilter: (value, record) => record.region
              // ? record.region
              //     .toString()
              //     .toLowerCase()
              //     .includes(value.toLowerCase())
              // : true,
              onFilter: (value, record) => record.region === value,
              //filterSearch:(input, record) => record.value.indexOf(input) > -1,
            //   sorter: {
            //     compare: (a, b) => sorter(a.region, b.region),
            //     multiple: 3,
            //   },
              responsive: ['sm'],

            },
            {
              title: 'ID',
              key: 'wbmt_id',
              width: '4%',
              fixed: 'left',
              dataIndex: 'wbmt_id',
              // ...getColumnSearchProps('wbmt_id'),
            //   sorter: (a, b) => sorter(a.wbmt_id, b.wbmt_id),
              sortDirections: ['descend', 'ascend'],
              responsive: ['sm'],
            },
            {
              title: 'Type',
              key: 'type',
              dataIndex: 'type',
              width: '2%',
              fixed: 'left',
              filters: [
                {
                  text: "Country Organization (CO)",
                  value: "CO",
                },
                {
                  text: "Transplant Center (TC)",
                  value: "TC",
                },
                {
                  text: "Region Organization (RO)",
                  value: "RO",
                },
                {
                  text: "Head Organization (HO)",
                  value: "HO",
                },
                {
                  text: "Donor Registry (DR)",
                  value: "DR",
                }
              ],
              onFilter: (value:string, record) => record.type.indexOf(value) === 0,
            //   sorter: (a, b) => sorter(a.type, b.type),
              //...getColumnSearchProps('get_type_display'),
              sortDirections: ['descend', 'ascend'],
              responsive: ['md'],
            },
            {
              title: 'Country',
              key: 'country_name',
              dataIndex: 'country_name',
              width: '16%',
              // ...getColumnSearchProps('country_name'),
            //   filterMode: 'tree',
              filters: countries,
              onFilter: (value:string, record) => record.country_iso.indexOf(value) === 0,
              filterSearch: true,
            //   sorter: (a, b) => sorter(a.country_name, b.country_name),
              sortDirections: ['descend', 'ascend'],
              responsive: ['md'],
            },
            // {
            //   title: 'ISO',
            //   key: 'country_iso',
            //   dataIndex: 'country_iso',
            //   ...getColumnSearchProps('country_iso'),
            //   sorter: {
            //     compare: (a, b) => a.country_iso.localeCompare(b.country_iso),
            //     multiple: 2
            //   },
            //   defaultSortOrder: ['descend'],
            // },
            {
              title: 'City',
              key: 'city',
              dataIndex: 'city',
              width: '8%',
              // ...getColumnSearchProps('city'),
            //   sorter: (a, b) => a.city.localeCompare(b.city),
              responsive: ['lg'],

            },
            {
              title: 'Name',
              key: 'name',
              dataIndex: 'name',
              width: '16%',

              // ...getColumnSearchProps('name'),
            //   sorter: (a, b) => a.name.localeCompare(b.name),
              responsive: ['md'],
            },
            {
                title: 'Active',
                key: 'active',
                dataIndex: 'active',
                width: '4%',
                responsive: ['sm'],
              },
            {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: '4%',
            render: (record:Center) => (<>
                {/* <Tooltip title={`Add / Edit Report for ${record.wbmt_id}`}>
                  <Button shape="circle" icon={<FileAddOutlined />} onClick={() => {setCenterSelected(record)}} />
                </Tooltip> */}
                <Tooltip title={`Open the Center Details`}>
                  <Link to={`/admin/center/${record.id}`} onClick={() => setDataloading(true)}> Open
                    {/* <Button disabled={false} shape="round" icon={<ZoomInOutlined />} /> */}
                  </Link>
                </Tooltip>
                 </>
            ),
        },
        ]
    
    return <Table columns={columns} dataSource={centers} size="small"/>
    }
export { CenterList }
