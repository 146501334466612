import  {useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { authAtom, initAppAtom, apploadingAtom, countriesAtom, centersAtom} from '../../_state';
import { DataActions } from '../../_actions';
import { Organization } from 'fhir/r5';
import { FileDoneOutlined, GlobalOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import { Line } from '@ant-design/plots';

function Home() {
  const auth = useRecoilValue(authAtom);
  const dataActions = DataActions();
  const [init, setInit] = useRecoilState(initAppAtom)
  const setLoading = useSetRecoilState(apploadingAtom)
  const allCountries = useRecoilValue(countriesAtom)
  const allCenters = useRecoilValue(centersAtom)

  const data = [
    { year: '2006', value: 7051 },
    { year: '2007', value: 7161 },
    { year: '2008', value: 7485 },
    { year: '2009', value: 57605 },
    { year: '2010', value: 61176 },
    { year: '2011', value: 65892 },
    { year: '2012', value: 70754 },
    { year: '2013', value: 73665 },
    { year: '2014', value: 75477 },
    { year: '2015', value: 80520 },
    { year: '2016', value: 85894 },
    { year: '2017', value: 90163 },
    { year: '2018', value: 93105 },
    { year: '2019', value: 88296 },
    { year: '2020', value: 59670 },
    { year: '2021', value: 24947 },
    { year: '2022', value: null },
    { year: '2023', value: null },
  ];

  const config = {
    data,
    autoFit: true,
    xField: 'year',
    yField: 'value',
    point: {
      size: 5,
      shape: 'diamond',
    },
    slider: {
      start: 0.1,
      end: 0.85,
    },
    yAxis:{
      title: {
        text: 'HSCT',
        style: {
          fontSize: 16,
        },
      },
    },
    xAxis:{
      title: {
        text: 'Year',
        style: {
          fontSize: 16,
        },
      },
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
  };

  let chart;

  // load static data for the first time after login
  useEffect(() => {
    if (auth && init) {
      dataActions.getDonortypes();
      dataActions.getIndications();
      dataActions.getAllRegions();
      dataActions.getAllCountries();
      dataActions.getCenters();
      setInit(false);
      setLoading(false);
    }
  }, []);

  return (
    <div className="App">
    {!auth? (
      <div>
        Please Login or Signup.
      </div>
      ):(
        <>
          <h1> Welcome to the Global Transplant Registry of the WBMT. </h1>
          <div>
            Select Actions in the menu bar at the left hand side.
          </div>
          <Row gutter={16}>
          <Col span={2}/>
          <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="Countries registered"
                  value={allCenters.filter(x => x.type === "CO").length}
                  precision={0}
                  // valueStyle={{ color: '#3f8600' }}
                  prefix={<GlobalOutlined />}
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="Centers registered"
                  value={allCenters.filter(x => x.type === "TC").length}
                  precision={0}
                  // valueStyle={{ color: '#3f8600' }}
                  prefix={<GlobalOutlined />}
                  suffix=""
                />
              </Card>
            </Col>
            {/* </Row>
            <Row gutter={16}> */}
            {/* <Col span={6}>
              <Card bordered={true}>
                <Statistic
                  title="Questionnaire responses"
                  value={2000}
                  precision={0}
                  // valueStyle={{ color: '#cf1322' }}
                  prefix={<FileDoneOutlined />}
                  suffix="per year"
                />
              </Card>
            </Col> */}
            <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="Transplant activities reported"
                  value={1500000/1000000}
                  precision={1}
                  // valueStyle={{ color: '#cf1322' }}
                  prefix={""}
                  suffix="Mio."
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="More Statistics"
                  value={""}
                  precision={0}
                  // valueStyle={{ color: '#3f8600' }}
                  prefix=""
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="More Statistics"
                  value={""}
                  precision={0}
                  // valueStyle={{ color: '#3f8600' }}
                  prefix=""
                  suffix=""
                />
              </Card>
            </Col>
            <Col span={2}/>
          </Row>
          <Row gutter={16}>
            <Col span={2}/>
            <Col span={20}>
              <Card bordered={true}>
                <Line {...config} onReady={(chartInstance) => (chart = chartInstance)} />

              </Card>
            </Col>
            <Col span={2}/>

          </Row>
          </>
      )
    }
    </div>)
}

export { Home };
